import { environment as env } from '../../../environments/environment';
import { Environment } from '../interfaces/environment.interface';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

export const API_BASE = (env as Environment).apiUrl;

export const API_BASE_V2 = (env as Environment).apiUrlV2;

export const ADMIN_URL = 'admin';

export const CATALOG = 'catalog';

export const INSTRUMENT = "instrument";

export const ORGANIZATION = "organization";

export const ORGANIZATION_UUID = 'organization_uuid';

export const ACTIVE_ORGANIZATION_UUID = 'active_organization_uuid';

export const ACTIVATION_CODE = 'activation_code';

export const SUPER_USER = 'superuser';
export const ORGANIZATION_ADMIN = 'organization_admin';
export const ORGANIZATION_EDITOR = 'organization_editor';
export const STAFF = 'staff';

export const USER_ORGANIZATION_LIST = 'user_organization_list';

export const USER_ROLES = {
  is_superuser: SUPER_USER,
  is_organization_admin: ORGANIZATION_ADMIN,
  is_organization_editor: ORGANIZATION_EDITOR,
  is_staff: STAFF
};

export const ERROR_MESSAGE_CLASS = 'error-message';

export const SUCCESS_MESSAGE_CLASS = 'success-message';

export const ERROR_MESSAGE_HORIZONTAL_POSITION: MatSnackBarHorizontalPosition =
  'right';

export const ERROR_MESSAGE_VERTICAL_POSITION: MatSnackBarVerticalPosition =
  'bottom';

export const SUCCESS_MESSAGE_VERTICAL_POSITION: MatSnackBarVerticalPosition =
  'top';

export const MESSAGE_TIME = 5e3;

export const INSTRUMENT_BULK_STATUSES = [
  {
    viewValue: 'Approve instruments',
    value: 1,
  },
  {
    viewValue: 'Reject instruments',
    value: 2,
  },
  {
    viewValue: 'Make public',
    value: 5,
  },
  {
    viewValue: 'Make private',
    value: 6,
  },
  {
    viewValue: 'Approve all scans',
    value: 3,
  },
  {
    viewValue: 'Approve all notes',
    value: 4,
  },
  // {
  //   viewValue: 'Make public notes',
  //   value: 7,
  // },
  // {
  //   viewValue: 'Make private notes',
  //   value: 8,
  // },
]

export const TEAM_ROLES = [
  { value: 0, viewValue: 'Member', key: '', },
  { value: 1, viewValue: 'Editor', key: 'is_organization_editor', },
  { value: 2, viewValue: 'Admin', key: 'is_organization_admin', }
];

export const INSTRUMENT_GET_PARAMS = {
  offset: 0,
  limit: 25,
  ordering: null,
  search: null,
  is_pagination: true,
  status: null,
  has_image: null,
  organization_uuid: '',
  sku: []
}
