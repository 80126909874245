import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import {API_BASE, API_BASE_V2, CATALOG} from '../../constants/general.constants';
import { Observable } from 'rxjs';
import { InstrumentCatalog, Vendor } from '../../interfaces/instrument.interface';

const VENDOR = "vendor";

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // Read

  get(
    offset: number = 0,
    limit: number = 25,
    sort: string = '',
    name__icontains = '',
    name = '',
  ): Observable<Vendor[]> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, sort, name__icontains, name }),
    };

    return this.http.get<Vendor[]>(
      `${API_BASE}${CATALOG}/${VENDOR}/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Get by id

  getById(
    id: string | number,
  ): Observable<Vendor> {
    return this.http.get<Vendor>(
      `${API_BASE}${CATALOG}/${VENDOR}/${id}/`
    );
  }

  create(data: any): Observable<Vendor> {
    (data as any) = GeneralHelpers.toMultipartFormData(data);
    return this.http
      .post<Vendor>(`${API_BASE}${CATALOG}/${VENDOR}/`, data);
  }

}
