import {HttpParams} from '@angular/common/http';
import { INSTRUMENT_GET_PARAMS, TEAM_ROLES, USER_ROLES } from '../../constants/general.constants';
import {SortRule} from '../../interfaces/sort-rule.interface';
import {User} from '../../interfaces/user.interface';
import { InstrumentGetParams } from '../../interfaces/instrument.interface';

export var multi = [
  {
    "name": "Germany",
    "series": [
      {
        "name": "1990",
        "value": 62000000
      },
      {
        "name": "2010",
        "value": 73000000
      },
      {
        "name": "2011",
        "value": 89400000
      }
    ]
  },

  {
    "name": "USA",
    "series": [
      {
        "name": "1990",
        "value": 250000000
      },
      {
        "name": "2010",
        "value": 309000000
      },
      {
        "name": "2011",
        "value": 311000000
      }
    ]
  },

  {
    "name": "France",
    "series": [
      {
        "name": "1990",
        "value": 58000000
      },
      {
        "name": "2010",
        "value": 50000020
      },
      {
        "name": "2011",
        "value": 58000000
      }
    ]
  },
  {
    "name": "UK",
    "series": [
      {
        "name": "1990",
        "value": 57000000
      },
      {
        "name": "2010",
        "value": 62000000
      }
    ]
  }
];

export const MAX_STRING_LENGTH = 2048;

export const MAX_LENGTH_AUTH0_STRING = 1048576;

export const MAX_NUMBER = 2147483647;

export const ONE = 1;

export const ZERO = 0

export const acceptedFileImages: string = 'image/*';

export const ORGANIZATION_STORAGE = 'organizations'

export const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const STATUS = [
  {
    value: '',
    viewValue: 'All'
  },
  {
    value: 0,
    viewValue: 'New'
  },
  {
    value: 1,
    viewValue: 'Waiting'
  },
  {
    value: 2,
    viewValue: 'Ready'
  },
  {
    value: 3,
    viewValue: 'Rejected'
  },
  {
    value: 4,
    viewValue: 'Deleted'
  }
]

export const CATALOG_INSTRUMENT_STATUSES = [
  {
    value: 1,
    viewValue: 'Waiting'
  },
  {
    value: 2,
    viewValue: 'Approved'
  },
  {
    value: 3,
    viewValue: 'Rejected'
  },
]

export const DEFAULT_CATALOG_INSTRUMENT_SEARCH_PARAMS: any = {
  status: null,
  hasImage: null,
  organization_uuid: null,
  isPublic: null,
  capturedMedicalInstrumentStatus: null,
  medicalInstrumentNoteStatus: null,
  isImagesApproved: null,
  hasCapturedMedicalInstrument: null
}

export interface GetParams {
  offset?: number;
  limit?: number;
  sort?: string;
  ordering?: string;
  search?: string;
  is_pagination?: boolean;
  status?: string;
  q?: string;
  name?: string;
  name__icontains?: string;
  has_image?: boolean;
  sku?: string;
  organization_uuid?: string;
  is_common_search?: boolean;
}

export const CATALOG_INSTRUMENT_STATUSES_VISIBILITY = [
  {
    value: true,
    viewValue: 'Public'
  },
  {
    value: false,
    viewValue: 'Private (org)'
  },
]

export const INSTRUMENT_NOTE_STATUSES = [
  {
    value: 1,
    viewValue: 'Waiting'
  },
  {
    value: 2,
    viewValue: 'Approved'
  },
  {
    value: 3,
    viewValue: 'Rejected'
  },
]

export const acceptedFilesAttachments: string =
  'image/*,video/*,.pdf';

// Supported files
const videoFiles: string = 'webm,mp4,ogg';
const audioFiles: string = 'mp3,acc,wav';
const imageFiles: string = 'jpg,jpeg,gif,tiff,webp,png';
// this files will be open with google preview
const documentFiles: string = 'doc,docx,xls,xlsx,ppt,pptx';
// this files will be open with iframe
const pdfFiles: string = 'pdf';

export class GeneralHelpers {

  static parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  static jsonStringify(data: any) {
    try {
      const stringifyData: string = JSON.stringify(data);
      return stringifyData;
    } catch (error) {
      const message: string = 'Cannot stringify data';
      console.error(message);
      return message;
    }
  }

  static jsonParse(data: any): any {
    try {
      const parsedData: string = JSON.parse(data);
      return parsedData;
    } catch (error) {
      const message: string = 'Cannot parse data';
      console.error(message);
      return message;
    }
  }

  static getParams(params: GetParams | any): HttpParams {
    let parameters = new HttpParams();
    for (const param in params) {
      if (Object.prototype.hasOwnProperty.call(params, param)) {
        if (params[param] === '' || params[param] === undefined || params[param] === null) {
          continue;
        }
        parameters = parameters.set(`${param}`, `${params[param]}`);
      }
    }

    return parameters;
  }

  static getTeamRole(teamData: Partial<User> | any): { value: string | any, viewValue: string } {
    let teamRole = TEAM_ROLES[0];
    for (let index = 0; index < TEAM_ROLES.length; index++) {
      const element = TEAM_ROLES[index];
      if (element.key && teamData[element.key]) {
        teamRole = element;
      }
    }
    return teamRole;
  }

  static booleanToYesNo(data: boolean): string {
    return data ? 'Yes' : 'No';
  }

  static booleanToActiveDeactivated(data: boolean): string {
    return data ? 'Active' : 'Deactivated';
  }

  static isEmail(value: string): boolean {
    value = value.trim();
    return EMAIL_REGEXP.test(value);
  }

  static processSearch(seacrh: string = ''): string {
    if (seacrh) {
      if (GeneralHelpers.isEmail(seacrh)) {
        return seacrh;
      }
      return seacrh === '' ? '' : seacrh.replace(
        /[&\/\\#,+()$~%.'":*?<>{}]/gi,
        ''
      );
    }
    return '';
  }

  static getSort(sort: SortRule): string {
    if (sort.direction === '') {
      return '';
    }
    let sortParam = sort.direction === 'desc' ? '-' : '';
    return `${sortParam}${sort.active}`;
  }

  static getSortAuth0(sort: SortRule): string {
    if (sort.direction === '') {
      return '';
    }
    let sortParam = sort.direction === 'desc' ? '-1' : '1';
    return `${sort.active}:${sortParam}`;
  }

  static getStatus(status: string) {
    let result = STATUS.find((element) => {
      return element.value === parseInt(status, 10);
    })
    return result ? result.viewValue : 'Unknown';
  }

  static getInstrumentCatalogStatus(status: string) {
    let result = CATALOG_INSTRUMENT_STATUSES.find((element) => {
      return element.value === parseInt(status, 10);
    })
    return result ? result.viewValue : 'Unknown';
  }

  static getOrgStatus(status) {
    return status == '1' ? 'Enabled' : 'Disabled';
  }

  static getOrganizationName(element) {
    return element.organization ? element.organization.name : '';
  }

  static toMultipartFormData(object: any): FormData {
    let formData = new FormData();
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        formData.append(key, object[key]);
      }
    }
    return formData;
  }

  static filterNullObject(object) {
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        if (object[key] === null) {
          delete object[key];
        }
      }
    }
    return object;
  }

  static getOrganizationFromMasterDataCompany(data: string, whatToget: 'id' | 'name') {
    const splittedData = data.split('|');
    if (Array.isArray(splittedData) && splittedData.length === 2) {
      return whatToget === 'id' ? splittedData[0] : splittedData[1];
    }
    return data;
  }

  static formatBytes(bytes, decimals = 2) {
    if (typeof bytes === 'string') return bytes;
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static getOrganizationNameById(id: number) {
    const organizations = GeneralHelpers.jsonParse(localStorage.getItem(ORGANIZATION_STORAGE));
    let name = '';
    for (const key in organizations) {
      if (Object.prototype.hasOwnProperty.call(organizations, key)) {
        const element = organizations[key];
        if (element.id === id) {
          name = element.name;
        }
      }
    }
    return name
  }

  static removeSpaces(val: string) {
    return val.trim();
  }

  static removeValidationAndHideField(fields: string[], formName: string, flagsname: string, context: any): void {
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];
      if (context[formName] !== undefined) {
        let currentFieldsAccessor = context[formName].get(element);
        currentFieldsAccessor.setValidators(null);
        currentFieldsAccessor.updateValueAndValidity();
      }
      context[flagsname][element] = true;
    }
  }

  static arrayToArrayOfObjectsByProp(propName: string = '', array: any[] = []): any[] {
    let arrayOfObjects = [];
    for (let i = 0; i < array.length; ++i) {
      let obj = {};
      obj[propName] = array[i];
      arrayOfObjects.push(obj);
    }
    return arrayOfObjects;
  }

  static getWordsFromString(str: string, howMany: number = 5, allowWords: number = 10): string {
    if (str) {
      if (str.split(' ').length > allowWords) {
        return `${str.split(' ').slice(0, howMany).join(' ')} ...`;
      } else {
        return str;
      }
    }
    return '';
  }

  static setRole(profile: User): string[] {
    const roles = USER_ROLES;
    const permissions = [];
    for (const key in roles) {
      if (Object.prototype.hasOwnProperty.call(roles, key)) {
        const role = roles[key];
        if (profile[key]) {
          permissions.push(role)
        }
      }
    }
    return permissions
  }

  static buildInstrumentGetParams(offset: number = 0,
                                  limit: number = 25,
                                  ordering: string | null = null,
                                  q: string | null = null,
                                  is_pagination: boolean = true,
                                  status: string | null = null,
                                  has_image: boolean | null = null,
                                  organization_uuid: string | null = '',
                                  is_public: boolean | null = null,
                                  captured_medical_instrument_status: string | null = null,
                                  medical_instrument_note_status: string | null = null,
                                  is_images_approved: boolean | null = null,
                                  has_captured_medical_instrument: boolean | null = null,
                                  is_common_search: boolean = false) {
    return Object.assign(INSTRUMENT_GET_PARAMS, {
      offset: offset,
      limit: limit,
      ordering: ordering,
      is_pagination: is_pagination,
      status: status,
      has_image: has_image,
      organization_uuid: organization_uuid,
      q: q,
      is_public: is_public,
      captured_medical_instrument_status: captured_medical_instrument_status,
      medical_instrument_note_status: medical_instrument_note_status,
      is_images_approved: is_images_approved,
      has_captured_medical_instrument: has_captured_medical_instrument,
      //is_common_search: (!(is_public !== null || captured_medical_instrument_status !== null || medical_instrument_note_status !== null || is_images_approved !== null || has_captured_medical_instrument !== null))
      is_common_search: is_common_search
    } as InstrumentGetParams)
  }

  static fileTypeFromString(
    data: any
  ): string {
    let url = data?.url;
    if(data?.video_id) {
      return 'video';
    }
    if (url === undefined) {
      return '';
    }
    let fileExtension = ''
    let fileType = '';
    if (url === null) {
      fileExtension = "";
    }
    let index = url.lastIndexOf("/");
    if (index !== -1) {
      url = url.substring(index + 1);
    }
    index = url.indexOf("?");
    if (index !== -1) {
      url = url.substring(0, index);
    }
    index = url.indexOf("#");
    if (index !== -1) {
      url = url.substring(0, index);
    }
    index = url.lastIndexOf(".");
    fileExtension = index !== -1
      ? url.substring(index + 1)
      : "";

    const fileExtensionRegex = RegExp(fileExtension, 'gi');

    if (fileExtensionRegex.test(videoFiles)) {
      fileType = 'video';
    } else if (fileExtensionRegex.test(audioFiles)) {
      fileType = 'audio';
    } else if (fileExtensionRegex.test(imageFiles)) {
      fileType = 'image';
    } else if (fileExtensionRegex.test(documentFiles)) {
      fileType = 'document';
    } else if (fileExtensionRegex.test(pdfFiles)) {
      fileType = 'pdf';
    }

    return fileType;
  }

  static detectPdfInURL(url: string) {
    if (url.includes('.pdf?')) {
      return true;
    }
    return false;
  }

  static detectSketchFabInURL(url: string) {
    if (url.includes('https://sketchfab.com')) {
      return true;
    }
    return false;
  }

  static mapNotesData(data: any) {
    const result: any = [];

    data.forEach((r: any, i: number) => {
      let tmpType = 'note';
      if (r.files && r.files.length > 0) {
        if (r.files[0].url && this.detectPdfInURL(r.files[0].url)) {
          tmpType = 'notePdf';
        } else if (r.files[0].video_id) {
          tmpType = 'noteVideo';
        }
        result.push({
          id: r.id,
          arrIndex: i,
          arrLength: data.length,
          src: r,
          srcTrusted: null,
          status: r.status,
          type: tmpType,
          allowEdit: r.allowEdit,
          allowOnlyDelete: false,
          userOwner: r.user_owner,
          uuid: r.uuid,
        });
      } else {
        if (r.description && this.detectSketchFabInURL(r.description)) {
          tmpType = 'noteIframe';
        }
        result.push({
          id: r.id,
          arrIndex: i,
          arrLength: data.length,
          src: r,
          srcTrusted: null,
          type: tmpType,
          allowEdit: r.allowEdit,
          allowOnlyDelete: false,
          userOwner: r.user_owner,
          uuid: r.uuid,
        });
      }
    });

    return result;
  }

  static sortArrayOfObjects<T>(arr: Array<T>, order: 'asc' | 'desc', prop: string): Array<T> {
    if (!Array.isArray(arr) || arr.length === 0) {
      return [];
    }
    return arr.sort((a: any, b: any) =>
      order === 'asc'
        ? a[prop] > b[prop]
        ? 1
        : b[prop] > a[prop]
          ? -1
          : 0
        : a[prop] > b[prop]
        ? -1
        : b[prop] > a[prop]
          ? 1
          : 0
    );
  }

}
