import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: 'instruments',
    loadChildren: () =>
      import('./pages/instruments/instruments.module').then(
        (mod) => mod.InstrumentsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'teams',
    loadChildren: () =>
      import('./pages/teams/teams.module').then((mod) => mod.TeamsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'people',
    loadChildren: () =>
      import('./pages/people/people.module').then((mod) => mod.PeopleModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'vision',
    loadChildren: () =>
      import('./pages/vision/vision.module').then((mod) => mod.VisionModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (mod) => mod.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'notes',
    loadChildren: () =>
      import('./pages/notes/notes.module').then(
        (mod) => mod.NotesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '500',
    loadChildren: () =>
      import('./static-pages/internalServerError/internalServerError.module').then(
        (mod) => mod.InternalServerErrorModule
      ),
  },
  {
    path: '404',
    loadChildren: () =>
      import('./static-pages/pageNotFound/pageNotFound.module').then(
        (mod) => mod.PageNotFoundModule
      ),
  },
  {
    path: 'not-authenticated',
    loadChildren: () =>
      import('./static-pages/notAuthenticated/notAuthenticated.module').then(
        (mod) => mod.NotAuthenticatedModule
      ),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
