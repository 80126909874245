import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { environment } from './../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModule } from './layout/header/header.module';
import { FooterModule } from './layout/footer/footer.module';
import { AuthModule } from '@auth0/auth0-angular';

// ────────────────────────────────────────────────────────────────────────────────
// Shared module
import { SharedModule } from './shared/shared.module';

// ────────────────────────────────────────────────────────────────────────────────
// Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
// ────────────────────────────────────────────────────────────────────────────────
// Toastr
import { ToastrModule } from 'ngx-toastr';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PermissionsService } from './shared/services/permission.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
  suppressScrollY: false,
};

// Auth data
const { apiUrl, apiUrlV2 } = environment;
const { domain, clientId, audience, redirectUri, scope, cacheLocation, useRefreshTokens } = environment.auth;
const httpInterceptor = {
  allowedList: [
    {
      uri: `https://${domain}/api/v2/*`,
      tokenOptions: {
        audience: `https://${domain}/api/v2/`,
        scope: 'read:current_user'
      }
    },
    { uri: `${apiUrl}*` },
    { uri: `${apiUrlV2}*` },
  ],
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // Layer
    HeaderModule,
    FooterModule,
    // Perfect scrollbar
    PerfectScrollbarModule,
    // Auth
    AuthModule.forRoot({
      domain,
      clientId,
      audience,
      redirectUri,
      scope,
      cacheLocation,
      useRefreshTokens,
      httpInterceptor,
    }),
    ToastrModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    SharedModule
  ],
  providers: [{
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
  },],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
  }
}
