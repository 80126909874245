<mat-toolbar>
  <div fxLayout="row" fxLayoutAlign="start center" class="w100">
    <a fxFlex="20" class="logo" href="javascript:void(0)" rel="noreferrer noopener" [routerLink]="'/dashboard'"></a>
    <div fxFlex="60" class="navigation" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <ng-container *ngFor="let item of navigation">
        <ng-container *ngxPermissionsOnly="item.permission">
          <button mat-raised-button [routerLink]="item.link" routerLinkActive="active" #rla="routerLinkActive"
            [color]="rla.isActive && 'accent'">{{item.label}}</button>
        </ng-container>
      </ng-container>
    </div>
    <div fxFlex="20" class="user-info" fxLayout="row" fxLayoutAlign="end center" *ngIf="auth.user$ | async as user">
      <div class="user-info-avatar" [ngStyle]="{
                                      'background-image': 'url(' + user.picture + ')'
                                    }" [matMenuTriggerFor]="menu">
      </div>
      <mat-menu #menu="matMenu">
        <a mat-menu-item href="https://www.layerjot.com/legal" target="_blank">
          <mat-icon>policy</mat-icon> <span>Legal</span>
        </a>
        <!-- <a mat-menu-item href="https://www.layerjot.com/contact-us" target="_blank">
          <mat-icon>inbox</mat-icon> <span>Inbox</span>
        </a> -->
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon> <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
