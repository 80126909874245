import { ORGANIZATION } from './../../constants/general.constants';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import { API_BASE, API_BASE_V2 } from '../../constants/general.constants';
import { User, UserAuth0, UserAuth0Response, UserResponse } from '../../interfaces/user.interface';
import { Observable } from 'rxjs';

const USER = 'user';
const AUTH0 = 'auth0'
const PROFILE = 'profile'

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create users

  create(body: any): Observable<any> {
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http.post(
      `${API_BASE_V2}${USER}/`,
      body
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Invite user

  invite(body: any): Observable<any> {
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http.post(
      `${API_BASE}${USER}/${AUTH0}/${ORGANIZATION}/`,
      body
    );
  }


  // ────────────────────────────────────────────────────────────────────────────────
  // Update user

  update(id: string, data: any) {
    (data as any) = GeneralHelpers.toMultipartFormData(data);
    return this.http.put(
      `${API_BASE_V2}${USER}/${id}/`,
      data
    );
  }

  updateAuth0(id: string, data: UserAuth0) {
    (data as any) = GeneralHelpers.toMultipartFormData(data)
    return this.http.put(
      `${API_BASE}${USER}/${AUTH0}/${id}/`,
      data
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Get users

  get(
    offset: number = 0,
    limit: number = 25,
    ordering: string = '',
    search: string = '',
    is_pagination: boolean = true,
    organization_uuid: string = '',
  ): Observable<UserResponse> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, ordering, search, is_pagination, organization_uuid }),
    };

    return this.http.get<UserResponse>(
      `${API_BASE_V2}${USER}/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Get users auth0

  getAuth0(
    offset: number = 0,
    limit: number = 25,
    sort: string = '',
    q: string = '',
  ): Observable<UserAuth0Response> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, sort, q }),
    };

    return this.http.get<UserAuth0Response>(
      `${API_BASE}${USER}/${AUTH0}/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Get by id
  getById(
    id: string | number
  ): Observable<User> {
    return this.http.get<User>(
      `${API_BASE_V2}${USER}/${id}/`
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Get Profile

  getProfile(): Observable<User> {
    return this.http.get<User>(
      `${API_BASE}${USER}/${PROFILE}/`
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Delete user

  delete(id: string, data: any) {
    (data as any) = GeneralHelpers.toMultipartFormData(data);
    return this.http.delete(
      `${API_BASE_V2}${USER}/${id}/`,
      {body: data}
    );
  }

  deleteAuth0(id: string) {
    return this.http.delete(
      `${API_BASE}${USER}/${AUTH0}/${id}/`
    );
  }

}
