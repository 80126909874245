import { Component, OnInit } from '@angular/core';
import { Navigation } from './navigation';

import { AuthService } from '@auth0/auth0-angular';
import {OrganizationsService} from "../../shared/services/organizations/organizations.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public navigation = Navigation
  constructor(public auth: AuthService, private organizationsService: OrganizationsService) { }

  ngOnInit() {
  }

  logout(): void {
    this.organizationsService.removeOrganizationUuid();
    this.organizationsService.removeActiveOrganizationUuid();
    // Call this to log the user out of the application
    this.auth.logout({ returnTo: window.location.origin });
  }

}
