import { Component } from '@angular/core';
import {mergeMap} from "rxjs";
import {PermissionsService} from "./shared/services/permission.service";
import {AuthService} from "@auth0/auth0-angular";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(private permissionsService: PermissionsService,
              private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.isAuthenticated$.pipe(
      mergeMap(async (status) => {
        if(status) {
          this.permissionsService.getPermissions()
        }
      })
    ).subscribe();
  }
}
