export const environment = {
  production: false,
  apiUrl: 'https://dev-api.layerjot.com/api/1.0/',
  apiUrlV2: 'https://dev-api.layerjot.com/api/v2/',
  inviteURL: 'https://dev-hub.layerjot.com',
  auth: {
    clientId: 'I4FVeeK2MgPSsDSn94v4ZIBQynsG68m7',
    domain: 'layerjot-dev.us.auth0.com',
    redirectUri: window.location.origin + '/',
    audience: 'https://layerjot-dev.us.auth0.com/api/v2/',
    scope: 'openid profile offline_access read:current_user update:current_user_metadata offline_access',
    cacheLocation: 'localstorage' as 'localstorage',
    useRefreshTokens: true,
  },
};
