import { Injectable } from '@angular/core';

import { NgxPermissionsService } from 'ngx-permissions';


import { environment as env } from '../../../environments/environment';
import { GeneralHelpers } from '../helpers/general/general.helper';
import {SUPER_USER, USER_ROLES} from '../constants/general.constants';
import { UsersService } from './users/users.service';
import { User } from '../interfaces/user.interface';
import { OrganizationsService } from './organizations/organizations.service';
import {ToastrService} from "ngx-toastr";
import {AuthService} from "@auth0/auth0-angular";

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  public userPermissions = [];
  constructor(
    private permissionsService: NgxPermissionsService,
    private usersService: UsersService,
    private organizationsService: OrganizationsService,
    private toastr: ToastrService,
    private auth: AuthService,
  ) { }

  getPermissions() {
    this.usersService.getProfile().subscribe({
      next: (profile: User) => {
        this.managePermissions(profile)
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => { },
    })
  }

  managePermissions(profile: User) {
    const permissions = GeneralHelpers.setRole(profile)
    this.permissionsService.loadPermissions(permissions);
    this.userPermissions = permissions;
    if(this.userPermissions.includes(SUPER_USER)) {
      const orgUUID = profile.organizations && profile.organizations.length ? profile.organizations[0].uuid : null;
      if(orgUUID) {
        this.organizationsService.setOrganizationUuid(orgUUID);
        this.organizationsService.setOrganizationUserList(profile.organizations);
      }
    } else {
      this.toastr.error("Permission denied");
      this.organizationsService.removeOrganizationUuid();
      this.organizationsService.removeActiveOrganizationUuid();
      this.auth.logout({ returnTo: window.location.origin });
    }
  }
}
