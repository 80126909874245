import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private router: Router
  ) { }

  /**
   * @param  {HttpRequest<any>} request?
   * @param  {HttpHandler} next?
   * @returns Observable
   */
  intercept(
    request?: HttpRequest<unknown>,
    next?: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(catchError((error) => this.errorHandler(error)));
  }

  /**
   * @param  {unknown} error
   * @returns Observable
   */
  private errorHandler(error: unknown): Observable<HttpEvent<unknown>> {
    this.renderErrors(error);

    return throwError(() => error);
  }

  /**
   * @param  {} error
   */
  private renderErrors(error): void {
    const errorMessages: string[] = this.parseErrorMessages(error);

    if (Array.isArray(errorMessages) && errorMessages.length > 0) {
      for (const element of errorMessages) {
        this.showError(`${error.status}: ${element}`);
      }
    }
  }

  /**
   * @param  {} error
   */
  private parseErrorMessages(error): string[] {
    const errorMessages: string[] = [];
    if (error && typeof error?.error === 'object') {
      const errorObj = error?.error;
      for (const key in errorObj) {
        if (Object.prototype.hasOwnProperty.call(errorObj, key)) {
          const element = errorObj[key];
          if (Array.isArray(element) && element.length > 0) {
            errorMessages.push(element[0])
          }
        }
      }
    }
    if (error && typeof error?.error === 'string' && error.status === 500) {
      errorMessages.push('Internal server error');
    }
    if (error && typeof error?.error === 'object' && typeof error?.error?.error === 'string') {
      errorMessages.push(error.error.error);
    }
    if (error && typeof error?.error === 'object' && typeof error?.error?.detail === 'string') {
      errorMessages.push(error.error.detail);
    }

    return errorMessages;
  }

  private showError(message: string): void {
    this.toastr.error('Error', message, {
      timeOut: 5e3,
      positionClass: 'toast-bottom-right'
    });
  }
}
