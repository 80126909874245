import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ACTIVATION_CODE,
  ACTIVE_ORGANIZATION_UUID,
  ADMIN_URL,
  API_BASE, API_BASE_V2,
  ORGANIZATION_UUID,
  USER_ORGANIZATION_LIST
} from '../../constants/general.constants';
import { GeneralHelpers, ORGANIZATION_STORAGE } from '../../helpers/general/general.helper';
import { Organization, OrganizationResponse } from '../../interfaces/organization.interface';
import { forkJoin, from, map, mergeMap, Observable, shareReplay } from 'rxjs';
import { MemoryStorage } from '../memory-storage/memory-storage.service';
import {environment} from "../../../../environments/environment";

const localStorage = new MemoryStorage('localStorage');
const ORGANIZATION = 'organization';
const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  private cachedOrganizations$: Observable<OrganizationResponse>;

  public organizationsData: Organization[] = [];

  public inviteCode: any = null;

  public organization: any = {
    id: ''
  };

  constructor(private http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // get by id

  getById(id: number | string) {
    return this.http.get<Organization>(
      `${API_BASE}${ADMIN_URL}/${ORGANIZATION}/${id}`
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // get with cache

  get(offset: number = 0,
    limit: number = 25,
    ordering: string = '',
    search: string = '',
    is_pagination: boolean = true,) {
    if (!this.cachedOrganizations$) {
      this.cachedOrganizations$ = this.getOrganizations(offset, limit, ordering, search, is_pagination).pipe(
        shareReplay(CACHE_SIZE)
      );
    }

    return this.cachedOrganizations$;
  }

  getOrganizations(
    offset: number = 0,
    limit: number = 25,
    ordering: string = '',
    search: string = '',
    is_pagination: boolean = true,
  ): Observable<OrganizationResponse> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, ordering, search, is_pagination }),
    };

    return this.http.get<OrganizationResponse>(
      `${API_BASE_V2}${ORGANIZATION}/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // create organizations

  create(data: { data: Organization }): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data.data)
    return this.http
      .post<Organization>(`${API_BASE_V2}${ORGANIZATION}/`, data);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Update

  update(id: string, data: { data: Organization }): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data.data)
    return this.http
      .put<Organization>(`${API_BASE_V2}${ORGANIZATION}/${id}`, data);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Delete

  delete(id: string): Observable<Object> {
    return this.http
      .delete<Organization>(`${API_BASE_V2}${ORGANIZATION}/${id}`);
  }

  addOrganizationName(data: any[]) {
    this.saveOrganizationsById(this.filterOrganizationIds(data));
  }

  filterOrganizationIds(data): number[] {
    // get organizations ids
    let organizationIds: number[] = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element && element.organization) {
        if (GeneralHelpers.getOrganizationNameById(element.organization) === '') {
          organizationIds.push(element.organization);
        }
      }
    }
    // Filter false values
    organizationIds = organizationIds.filter(Boolean);
    // Make unique
    organizationIds = [...new Set(organizationIds)];

    return organizationIds;
  }

  saveOrganizationsById(organizationIds) {
    if (Array.isArray(organizationIds) && organizationIds.length === 0) {
      return;
    }
    from(organizationIds).pipe(
      mergeMap(id => this.getById(id as any)),
      map((organization: Organization) => {
        return this.organizationsData.push({
          id: organization.id,
          name: organization.name
        })
      })
    ).subscribe({
      next: () => { },
      complete: () => {
        localStorage.setItem(ORGANIZATION_STORAGE, GeneralHelpers.jsonStringify(this.organizationsData));
      }
    })
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Set Organization Uuid

  setOrganizationUuid(uuid: string) {
    localStorage.setItem(ORGANIZATION_UUID, uuid);
  }

  setActiveOrganizationUuid(uuid: string) {
    localStorage.setItem(ACTIVE_ORGANIZATION_UUID, uuid);
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Get Organization Uuid

  getOrganizationUuid(): string {
    const uuid = localStorage.getItem(ORGANIZATION_UUID);
    return uuid ? uuid : '';
  }

  getActiveOrganizationUuid(): string {
    const uuid = localStorage.getItem(ACTIVE_ORGANIZATION_UUID);
    return uuid ? uuid : this.getOrganizationUuid();
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Remove Organization Uuid

  removeOrganizationUuid() {
    localStorage.removeItem(ORGANIZATION_UUID);
  }

  removeActiveOrganizationUuid() {
    localStorage.removeItem(ACTIVE_ORGANIZATION_UUID);
  }

  // Set organiations that are abaialbe for switch in user profile
  setOrganizationUserList(organizations: Organization[]) {
    if (Array.isArray(organizations) && organizations.length > 0) {
      localStorage.setItem(USER_ORGANIZATION_LIST, GeneralHelpers.jsonStringify(organizations));
    }
  }

  getOrganizationUserList(): Organization[] {
    const organizationList = localStorage.getItem(USER_ORGANIZATION_LIST);
    return organizationList ? GeneralHelpers.jsonParse(organizationList) : [];
  }

  public getOrganizationFromProfile(): Organization | any {
    const orgList = this.getOrganizationUserList();
    const orgUuid = this.getOrganizationUuid();
    if (orgList.length === 0 && orgUuid === '') {
      return null;
    }
    const currentId = orgUuid ? orgUuid : orgList[0].uuid;
    for (let index = 0; index < orgList.length; index++) {
      const element = orgList[index];
      if (element.uuid === currentId) {
        return element;
      }
    }
    return null;
  }

  getByUuid(id: number | string) {
    return this.http.get<Organization>(
      `${API_BASE_V2}${ORGANIZATION}/${id}/`
    );
  }

  getAndSaveUserOrganization(organizationId: string) {
    this.getByUuid(organizationId).subscribe({
      next: (organization: Organization) => {
        this.organization = organization
        this.setOrganizationInviteCode(organization.invitation_code);
      }
    })
  }

  // ─────────────────────────────────────────────────────────────────────
  // Set organization invite code
  setOrganizationInviteCode(code) {
    // let code: any = 'No code'
    // const organization = this.getOrganizationFromProfile();
    // if (organization !== null) {
    //   code = organization.invitation_code;
    // }
    this.inviteCode = code;
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get invite link
  getInviteLink(code = this.inviteCode) {
    const time = new Date().getTime();
    const hash = this.hashCode(this.getInviteSecret(code, time));
    return `${environment.inviteURL}/invite?${ACTIVATION_CODE}=${code}&h=${hash}&t=${time}`;
  }

  getInviteSecret(inviteCode: string, time: any) {
    const secret = 'hub-2023';
    return inviteCode + secret + time;
  }

  hashCode(str: string) {
    var hash = 0,
      i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return Math.abs(hash);
  }

}
