<div class="title">Image preview</div>
<div class="close-btn">
  <button mat-icon-button aria-label="Close dialog" mat-dialog-close (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="image-holder p-0 m-0" mat-dialog-content>
  <img
    *ngIf="file.url"
    [src]="file.url"
    alt="fullscreen image"
    class="position-absolute top-50 start-50 translate-middle mh-100 mw-100 mx-auto"
  >
  <div class="vision-actions text-center" *ngIf="data.vision">
    <button class="vision-image-button" mat-icon-button [color]="file.to_be_removed ? 'accent' : 'warn'"
            aria-label="Update image" (click)="updateFileStatus(file?.id, file.to_be_removed)">
      <mat-icon *ngIf="!file.to_be_removed">delete</mat-icon>
      <mat-icon *ngIf="file.to_be_removed">restore_from_trash</mat-icon>
    </button>
  </div>
  <div
    class="position-absolute top-50 start-50 translate-middle w-100 px-3"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="data?.files && data.files.length > 1"
  >
    <button class="bg-secondary" mat-fab aria-label="Previous image" (click)="goPrevious()" [disabled]="noPrevious">
      <mat-icon class="text-white ps-1">arrow_back_ios</mat-icon>
    </button>
    <button class="bg-secondary" mat-fab aria-label="Next image" (click)="goNext()" [disabled]="noNext">
      <mat-icon class="text-white">arrow_forward_ios</mat-icon>
    </button>
  </div>
</div>
<div class="text-center mt-1" *ngIf="data?.files && data.files.length > 1">
  <span
    *ngFor="let file of data?.files; let i = index;"
    class="bg-secondary slide mx-1"
    [ngClass]="{
      'bg-dark': data.index === i
    }"
    (click)="data.index = i; setFile()"
  ></span>
</div>
