import { ORGANIZATION_ADMIN, SUPER_USER } from "src/app/shared/constants/general.constants";

export const Navigation = [
  {
    label: "dashboard",
    link: '/dashboard',
    permission: [SUPER_USER]
  },
  {
    label: "instruments",
    link: '/instruments',
    permission: [SUPER_USER, ORGANIZATION_ADMIN]
  },
  {
    label: "teams",
    link: '/teams',
    permission: [SUPER_USER]
  },
  {
    label: "People",
    link: '/people',
    permission: [SUPER_USER, ORGANIZATION_ADMIN]
  },
  {
    label: "vision",
    link: '/vision',
    permission: [SUPER_USER, ORGANIZATION_ADMIN]
  },
  // {
  //   label: "notes",
  //   link: '/notes',
  //   permission: [SUPER_USER, ORGANIZATION_ADMIN]
  // }

]
