import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '@auth0/auth0-angular';
import { debounceTime, distinctUntilChanged, EMPTY, fromEvent, map, Observable, startWith, switchMap, tap } from 'rxjs';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import { Vendor } from '../../interfaces/instrument.interface';
import { UserResponse } from '../../interfaces/user.interface';
import { MessageService } from '../../services/message/message.service';
import { UsersService } from '../../services/users/users.service';
import { VendorService } from '../../services/vendor/vendor.service';

@Component({
  selector: 'app-instruments-advanced-filter-dialog',
  templateUrl: './instruments-advanced-filter-dialog.component.html',
  styleUrls: ['./instruments-advanced-filter-dialog.component.scss']
})
export class InstrumentsAdvancedFilterDialogComponent implements OnInit, AfterViewInit {
  loading = false;

  // ────────────────────────────────────────────────────────────────────────────────
  // User autocmplete
  usersAutocompleteData!: Observable<User[]>;
  userLoading = false;
  noUsersFound = false;
  userLoadById = false;
  @ViewChild('userAutocompleteInput') userAutocompleteInput!: ElementRef;
  // ────────────────────────────────────────────────────────────────────────────────

  // ────────────────────────────────────────────────────────────────────────────────
  // Vendors autocomplete
  public vendorsAutocompleteData!: Observable<Vendor[]> | undefined;
  @ViewChild('vendorAutocompleteInput') vendorAutocompleteInput!: ElementRef;

  vendorLoading = false;
  noVendorsFound = false;
  vendorLoadById = false;
  // ────────────────────────────────────────────────────────────────────────────────

  selectedFilters: any = {
    lastUpdateBy: '',
    lastUpdateDate: '',
    numberOfCatalogImages: '',
    vendor: '',
    status: '',
    visibility: '',
    scans: '',
    notes: '',
  }

  catalogImagesList: any[] = [
    {
      value: 'any',
      viewValue: 'Any',
    },
    {
      value: 'none',
      viewValue: 'None',
    },
    {
      value: 'one',
      viewValue: 'one',
    },
    {
      value: 'more_then_1',
      viewValue: 'More then 1',
    }
  ]

  statusValues: any[] = ['Approved', 'Rejected', 'Waiting approval'];
  visibilityValues: any[] = ['Private', 'Public'];
  scansValues: any[] = ['None', 'Waiting approval'];
  notesValues: any[] = ['None', 'Waiting approval'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private messageService: MessageService,
    private usersService: UsersService,
    private vendorService: VendorService) { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.prepareUsersAutocomplete();
    this.prepareVendorsAutocomplete();
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Autocomplete users

  private prepareUsersAutocomplete() {

    this.usersAutocompleteData = fromEvent(this.userAutocompleteInput.nativeElement, 'keyup')
      .pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged() as any,
        switchMap(() => {
          return this.getUsers(this.userAutocompleteInput.nativeElement.value)
        })
      );
  }

  private getUsers(val: string): Observable<any[]> {
    if (typeof val !== 'string' || val === '') {
      return EMPTY;
    }
    this.noUsersFound = false;
    val = GeneralHelpers.removeSpaces(val);
    return this.usersService.get(0, 25, '', val).pipe(
      map((response: UserResponse) =>
        response.results.filter((option) => {
          return (
            option?.full_name?.toLowerCase().indexOf(val.toLowerCase()) === 0 || option?.email?.toLowerCase().indexOf(val.toLowerCase()) === 0
          );
        })
      ),
      tap((users: User[]) => {
        if (users.length === 0) {
          this.noUsersFound = true;
        }
      })
    );
  }

  public displayUser(user: User) {
    if (typeof user === 'object' && user !== null) {
      return `${user['id'] ? user['id'] : 'No id'} | ${user.email ? user.email : 'No email'} | ${user['full_name'] ? user['full_name'] : 'No fullname'}`;
    }
    return 'No data'
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // ────────────────────────────────────────────────────────────────────────────────
  // Autocomplete vendors

  private prepareVendorsAutocomplete() {
    this.vendorsAutocompleteData = fromEvent(this.vendorAutocompleteInput.nativeElement, 'keyup')
      .pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged() as any,
        switchMap(() => {
          return this.getVendors(this.vendorAutocompleteInput.nativeElement.value)
        })
      );
  }

  private getVendors(val: string): Observable<any[]> {
    if (typeof val !== 'string' || val === '') {
      return EMPTY;
    }
    this.noVendorsFound = false;
    return this.vendorService.get(0, 25, '', val).pipe(
      map((response: Vendor[]) =>
        response.filter((option) => {
          return (
            option?.name?.toLowerCase().indexOf(val.toLowerCase()) === 0
          );
        })
      ),
      tap((vendors: Vendor[]) => {
        if (vendors.length === 0) {
          this.noVendorsFound = true;
        }
      })
    );
  }

  public displayVendor(vendor: Vendor) {
    if (typeof vendor === 'object' && vendor !== null) {
      return `${vendor.name ? vendor.name : 'No name'}`;
    } else {
      return 'No data'
    }
  }
  //────────────────────────────────────────────────────────────────────────────────
}
