import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import {ACTIVE_ORGANIZATION_UUID, ORGANIZATION_UUID, SUPER_USER} from "../constants/general.constants";
import { MemoryStorage } from '../services/memory-storage/memory-storage.service';
import {PermissionsService} from "../services/permission.service";

const localStorage = new MemoryStorage('localStorage');

const v2API = /\/api\/v2(.*)/;
const instrumentsV2API = /\/api\/v2\/catalog\/instrument(.*)/;
const userV2API = /\/api\/v2\/user(.*)/;
const organizationV2API = /\/api\/v2\/organization(.*)/;

@Injectable()
export class OrganizationHeaderInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.patch(request));
  }

  private patch(request: HttpRequest<any>) {
    const orgUuid = localStorage.getItem(ORGANIZATION_UUID);

    if (instrumentsV2API.test(request.url)) {
      return request;
    }

    if (organizationV2API.test(request.url)) {
      return request;
    }

    if (userV2API.test(request.url)) {
      return request;
    }

    // if (activeOrgUuid && instrumentsV2API.test(request.url)) {
    //   return request.clone({
    //     headers: request.headers.set('LJ-User-Organization', activeOrgUuid as string),
    //   });
    // }

    // if (activeOrgUuid && userV2API.test(request.url)) {
    //   return request.clone({
    //     headers: request.headers.set('LJ-User-Organization', activeOrgUuid as string),
    //   });
    // }

    if (orgUuid && v2API.test(request.url)) {
      return request.clone({
        headers: request.headers.set('LJ-User-Organization', orgUuid as string),
      });
    }

    return request;
  }
}
