import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowRef } from './helpers/window/window.ref';
// Import the injector module and the HTTP client module from Angular
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Import the HTTP interceptor from the Auth0 Angular SDK
import { ConfirmDialogModule } from './modules/confirm-dialog/confirm-dialog.module';
import { HttpErrorInterceptor } from './interceptors/error.interceptor';
import { RouterModule } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CookieService } from './services/cookie/cookie.service';
import { ImageDialogModule } from './modules/image-dialog/image-dialog.module';
import { OrganizationHeaderInterceptor } from './interceptors/organization-header.interceptor';
import { InstrumentsAdvancedFilterDialogModule } from './modules/instruments-advanced-filter-dialog/instruments-advanced-filter-dialog.module';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ConfirmDialogModule,
    MatSnackBarModule,
    RouterModule,
    ImageDialogModule,
    InstrumentsAdvancedFilterDialogModule
  ],
  providers: [
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrganizationHeaderInterceptor,
      multi: true,
    },
    CookieService,
    WindowRef,
  ],
})
export class SharedModule { }
